<template>
  <div>
    <ValidationObserver v-slot="{ handleSubmit }">
      <b-form ref="form" @submit.prevent="handleSubmit(editProfile)" class="curva-form mb-5 p-5">
      <b-row class="justify-content-center">
<!--        <b-col lg="4">
          <cropper-images
            label="Profile photo"
            nameOfImage="logo.jpg"
            :multi="false"
          />
        </b-col>-->
        <b-col lg="6" class="pt-3">
<!--          {{ info }}-->
          <b-row>
            <b-col lg="12" class="mb-1">
              <input-form :placeholder="$t('forms.name')" v-model="info.name"
                          :validate="'required|min:8'" :name="`Name`"/>
            </b-col>

            <b-col lg="12" class="mb-1">
              <input-form :placeholder="$t('forms.email')" v-model="info.email"
                          :validate="'required|email'" :name="`Email Address`"/>
            </b-col>

            <b-col lg="12" class="mb-1">
              <input-form :placeholder="$t('forms.phone')" v-model="info.phone"
                          :validate="'required|numeric'" :name="`Mobile number`"/>
            </b-col>

            <b-col lg="6">
              <input-form :placeholder="'Date Of Birth'" type="date"
                          :max="new Date().toLocaleDateString('en-ca')"
                          v-model="info.birthdate" :name="`Date of birth`"/>
            </b-col>

            <b-col lg="6">
              <main-select :placeholder="$t('forms.gender')" v-model="info.gender" :searchable="false"
                           :options="genderOptions" label="value" :reduce="data => data.key"
                           :validate="'required'" :name="`Gender`" class="curva-main-select"
              ></main-select>
            </b-col>

            <b-col lg="12" class="mb-1">
              <input-form :placeholder="$t('forms.currentPass')" v-model="info.password"
                          :validate="'required'" type="password" :name="`Password`"/>
            </b-col>

            <!-- <b-col lg="6" class="mb-4">
              <input-form :placeholder="$t('forms.confirmationPass')" v-model="info.password_confirmation"
                           :validate="'required'" type="password" :name="`Password confirmation`"/>
            </b-col> -->

            <b-col lg="12" class="text-right">
              <b-button class="grey-btn small-rounded-btn mx-3" @click="$router.push({name:'profileInfo'})">{{ $t('forms.discard') }}</b-button>
              <b-button class="curva-granola-btn small-rounded-btn" type="submit">{{ $t('forms.save') }}</b-button>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-form>
    </ValidationObserver>
  </div>
</template>

<script>
import { core } from '@/config/pluginInit'
import ProfileServices from '@/modules/profile/services/profile.services'
export default {
  data () {
    return {
      info: {
        name: '',
        email: '',
        birthdate: '',
        gender: '',
        phone: '',
        password: ''
      },
      showPassword: false,
      genderOptions: [
        {
          key: 'male',
          value: this.$t('male')
        },
        {
          key: 'female',
          value: this.$t('female')
        }
      ]
    }
  },
  methods: {
    discard () {
      this.$refs.form.reset()
    },
    editProfile () {
      ProfileServices.editProfile({ ...this.info, fullname: this.info.name, mobile: this.info.phone, B_date: this.info.birthdate }).then(res => {
        core.showSnackbar(res.data.message)
        this.$router.push({ name: 'profileInfo' })
      })
    },
    getProfileData () {
      ProfileServices.getProfileData().then(res => {
        this.info = res.data.data
      })
    }
  },
  created () {
    this.getProfileData()
  },
  mounted () {
    core.index()
  }
}
</script>
